import {
  Box,
  CircularProgress,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { green } from "@mui/material/colors";

const customTheme = createTheme({
  palette: {
    primary: {
      light: green["A100"],
      main: green["A400"],
      dark: green["A700"],
      contrastText: "#fff",
    },
  },
});

export default function LoaddingSreen() {
  return (
    <ThemeProvider theme={customTheme}>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        <CircularProgress />
      </Box>
    </ThemeProvider>
  );
}
