export const recaptcha_site_key = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
export const api = process.env.REACT_APP_API_URL;


const setting = {
  api: api,
  recaptcha_site_key: recaptcha_site_key,
};



export default setting;
