import { configureStore } from "@reduxjs/toolkit";

import formPendaftaranHandlerReducer from "../features/pendaftaran/PendaftaranHandler";
import cariTiketHandlerReducer from "../features/cariTiket/CariTiketHandler";
import formIkmHandlerReducer from "../features/ikm/bappenda/FormHandler";

export default configureStore({
  reducer: {
    cariTiketHandler: cariTiketHandlerReducer,
    formPendaftaranHandler: formPendaftaranHandlerReducer,
    formIKMBappendaHandler: formIkmHandlerReducer,
  },
});
