import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { storePendaftaran } from "../../services/Pendaftaran";

export const storeForm = createAsyncThunk(
  "form-pendaftaran-handler/store-form",
  async (datas, thunkAPI) => {
    try {
      const dt = await storePendaftaran(datas);
      return thunkAPI.fulfillWithValue(dt.data);
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data.errors);
    }
  }
);

const initialState = {
  dataPendaftaran: {},
  layanan: [],
  sesiLibur: [],
  isLoadding: true,
  activeStep: 0,
  data: {},
  isError: false,
  errorMessage: null,
};

const PendaftaranHandler = createSlice({
  name: "form-pendaftaran-handler",
  initialState,
  reducers: {
    setDataPendaftaran: (state, action) => {
      state.dataPendaftaran = action.payload;
    },
    nextStep: (state, action) => {
      state.data = { ...state.data, ...action.payload };
      state.activeStep += 1;
    },
    prevStep: (state, action) => {
      state.data = { ...state.data, ...action.payload };
      state.activeStep -= 1;
    },
    setLoading: (state) => {
      state.isLoadding = true;
    },
    unsetLoading: (state) => {
      state.isLoadding = false;
    },
    setLayanan: (state, action) => {
      state.layanan = action.payload;
    },
    setSesiLibur: (state, action) => {
      state.sesiLibur = action.payload;
    },
    closeError: (state) => {
      state.isError = false;
      state.errorMessage = null;
    },
  },
  extraReducers: {
    [storeForm.pending]: (state) => {
      state.isLoadding = true;
    },
    [storeForm.fulfilled]: (state, action) => {
      console.log(action.payload);
      window.location.href = `${window.location.origin}/tiket?tiket=${action.payload.tiket}&kode=${action.payload.kode}&success=true`;
    },
    [storeForm.rejected]: (state, action) => {
      state.isError = true;
      state.errorMessage = action.payload[Object.keys(action.payload)[0]][0];
    },
  },
});

export const {
  setDataPendaftaran,
  nextStep,
  prevStep,
  setLoading,
  unsetLoading,
  setLayanan,
  setSesiLibur,
  closeError,
} = PendaftaranHandler.actions;

export default PendaftaranHandler.reducer;
