import { lazy, Suspense } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";

import LoaddingSreen from "./components/LoaddingSreen";

const Layout = lazy(() => import("./layouts/AntreanLayout"));
const IKMLayout = lazy(() => import("./layouts/IKMLayout"));

const FormCariTiket = lazy(() => import("./views/CariTiket"));
const FormPendaftaran = lazy(() => import("./views/FormPendaftaran"));
const HomePendaftaran = lazy(() => import("./views/Home"));
const Tiket = lazy(() => import("./views/Tiket"));

const PanggilanAtrean = lazy(() => import("./views/PemanggilanAntrean"));

// IKM Section
const IKMBappenda = lazy(() => import("./views/ikm/IKMBappenda"));

function App() {
  return (
    <Router>
      <Suspense fallback={<LoaddingSreen />}>
        <Routes>
          <Route path="/pemanggilan" element={<PanggilanAtrean />} />
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<HomePendaftaran />} />
            <Route path="/tiket" element={<Tiket />} />
            <Route path="pendaftaran" element={<FormPendaftaran />} />
            <Route path="cari-tiket" element={<FormCariTiket />} />
          </Route>
          <Route path="/ikm" element={<IKMLayout title="IKM Bappenda" />}>
            <Route path="bappenda" element={<IKMBappenda />} />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
