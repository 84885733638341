import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { storeJawaban } from "../../../services/ikm/Bappenda";
const initialState = {
  isLoadding: false,
  activeStep: 0,
  data: {},
  hasil: 0,
  isError: false,
};

export const storeForm = createAsyncThunk(
  "form-handler/store-form",
  async (datas, thunkAPI) => {
    try {
      // const { data } = thunkAPI.getState();

      // console.log(data);
      // data = { ...data, ...datas };

      const dt = await storeJawaban(datas);
      return dt.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const FormHandler = createSlice({
  name: "form-handler",
  initialState,
  reducers: {
    nextStep: (state, action) => {
      state.data = { ...state.data, ...action.payload };
      state.activeStep += 1;
    },
    prevStep: (state, action) => {
      state.data = { ...state.data, ...action.payload };
      state.activeStep -= 1;
    },
    setLoadding: (state) => {
      state.isLoadding = true;
    },
    unsetLoadding: (state) => {
      state.isLoadding = false;
    },
  },
  extraReducers: {
    [storeForm.pending]: (state) => {
      state.activeStep += 1;
      state.isLoadding = true;
    },
    [storeForm.fulfilled]: (state, action) => {
      state.isLoadding = false;
      state.hasil = action.payload.hasil;
    },
    [storeForm.rejected]: (state, action) => {
      state.isLoadding = false;
      state.isError = true;
    },
  },
});

export const { nextStep, prevStep, submitJawaban, setLoadding, unsetLoadding } =
  FormHandler.actions;

export default FormHandler.reducer;
