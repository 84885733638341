import axios from "axios";
import API from "../APIHandler";

const { APIHandler } = API;

export function getPertanyaan() {
  return APIHandler.get("/ikm-bappenda?filter[id_jenis_pertanyaan]=3");
}

export function storeJawaban(data) {
  return APIHandler.post("/ikm-bappenda", data);
}
