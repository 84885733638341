import axios from "axios";
import setting from "../config/main";

export const APIHandler = axios.create({
  baseURL: setting.api,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
const token = JSON.parse(localStorage.getItem("user"))?.access_token ?? "";

export const AuthAPIHandler = axios.create({
  baseURL: setting.api_dashboard,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  },
});

const API_CALL = {
  APIHandler: APIHandler,
  AuthAPIHandler: AuthAPIHandler,
};

export default API_CALL;
