import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllTicket } from "../../services/CariTiket";

const initialState = {
  activeStep: 0,
  listTiket: [],
};

const CariTiketHandler = createSlice({
  name: "cari-tiket-handler",
  initialState,
  reducers: {
    setListTicket: (state, action) => {
      state.listTiket = action.payload;
      state.activeStep += 1;
    },
    nextStep: (state) => {
      state.activeStep += 1;
    },
  },
});

export const { setListTicket, nextStep } = CariTiketHandler.actions;

export default CariTiketHandler.reducer;
