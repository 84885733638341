import axios from "axios";
import API from "./APIHandler";
import qs from "qs";
const { APIHandler } = API;

export function getDetailPendaftaran() {
  return APIHandler.get("pendaftaran-antrean");
}

export function getUpdatePendaftaran(data) {
  return APIHandler.get("pendaftaran-antrean/" + data);
}

export function storeUpdatePendaftaran(data) {
  return APIHandler.put("pendaftaran-antrean/" + data);
}

export function getSesiLibur(data) {
  return APIHandler.get("sesi-libur", {
    params: {
      tanggal: data,
    },
    
  });
}

export function getLayanan(data) {
  return APIHandler.get("getLayanan", { params: data });
}

export function storePendaftaran(data) {
  return APIHandler.post("/pendaftaran-antrean", data);
}
