import API from "./APIHandler";
const { APIHandler } = API;

export function getAllTicket(data) {
  return APIHandler.get("/getTiket", {
    params: data
  });
}

export function getTiket(data) {
  return APIHandler.get("/getTiket/" + data.kode, { params: { tiket: data.tiket } });
}
